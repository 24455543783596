<template>

  <div class="calendar">
    <div v-if="!calendarData">
      <div>Por favor aguarde...</div>
    </div>
    <div v-else v-cloak>
      <div class="header">
        <div class="navigation">&laquo;</div>
        <div class="label">{{ this.calendarData.current_month_label}}</div>
        <div class="navigation">&raquo;</div>
      </div>
      <div class="weekdays">
        <div class="weekday">D</div>
        <div class="weekday">S</div>
        <div class="weekday">T</div>
        <div class="weekday">Q</div>
        <div class="weekday">Q</div>
        <div class="weekday">S</div>
        <div class="weekday">S</div>
      </div>
      
      <div class="days">
        
        <div class="day" v-for="(day, index) in calendarData.days" :key="index">
          {{ day.day }}
        </div>




      </div>
    </div>
  </div>

</template>

<style scoped>
  .header 
  {
   display: flex; 
   justify-content: space-between;
  }
  .weekdays,
  .days
  {
    display: grid;
    grid-template-columns: repeat(7,1fr);
  }
</style>


<script>
export default {
  name: 'HolmyCalendar',
  props:{
    month: Number,
    year: Number
  },
  data() {
    return {
      
      calendarData: null
    }
  },
  mounted() {
    let endpoint = 'http://localhost:8000/holmylive/wp-json/holmyapi/v1/calendar?y=2022&m=9';
    fetch(endpoint, {
        credentials: "include",
        headers: {
          'content-type': 'application/json',
        }
      })
      .then(res => res.json())
      .then(data => {
        // console.log(data);
        this.calendarData = data;
      } )
      .catch(err => console.log(err.message) )    

  }
}
</script>

